*,
::before,
::after {
  padding: 0;
  margin: 0;
  border: none;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* outline: 1px solid red; */
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

:root {
  --clr-black: #000000;
  --clr-white: #ffffff;
  --clr-dark-blue: #0ca8df;
  --clr-dark-navy: #0a1e41;

  --radius-12: 12px;
}

.pac-container {
  z-index: 9999 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
  font-family: inherit;
  color: inherit;
  border-radius: 0;
}

a {
  color: var(--clr-dark-blue);
  text-decoration: none;
}

.statci-pages {
  color: var(--clr-dark-navy);
  font-weight: 600;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

.no-scrollbar {
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
}

/* Firefox */
/* Style the scrollbar */
/* ::-webkit-scrollbar {
  width: 6px; 
} */

/* ::-webkit-scrollbar-thumb {
  background-color: #d4d4d4; 
  border-radius: 4px;
} */

/* ::-webkit-scrollbar-thumb:hover {
  background-color: #555; G
} */

/* ::-webkit-scrollbar-track {
  background: #f1f1f1; 
} */

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.img-contain {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.img-filter-brighness {
  filter: brightness(0);
}

.text-center {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.job-title-select .Mui-disabled,
.speciality-select .Mui-disabled {
  cursor: pointer !important;
}
.job-title-select .MuiSelect-select {
  pointer-events: none !important;
}

.button-hover {
  transition: all 0.5s ease-in;
  box-shadow: 0 0 0 rgb(119, 119, 119);
}
.button-hover:hover {
  transition: all 0.4s ease-in;
  box-shadow: 0 0px 8px rgb(136, 136, 136);
}

.back-button {
  background: var(--clr-dark-navy) !important;
  border-radius: 6px !important;
  height: 32px !important;
  width: 32px !important;
}
.back-button:hover {
  background: #0f2346 !important;
}

.plus-button {
  background: var(--clr-black) !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  &:hover {
    background-color: #0f2346 !important;
  }
}

.plus-button-02 {
  background: var(--clr-black) !important;
  border-radius: 50% !important;
  width: 24px !important;
  height: 24px !important;
  &:hover {
    background-color: #0f2346 !important;
  }
}
.plus-button-03 {
  background: var(--clr-black) !important;
  border-radius: 50% !important;
  width: 35px !important;
  height: 35px !important;
  &:hover {
    background-color: #0f2346 !important;
  }
}

.searchInput input {
  padding-top: 13px;
  padding-bottom: 13px;
}

.main-modal .MuiModal-backdrop,
.notification-dropdown .MuiModal-backdrop,
.profile-dropdown .MuiModal-backdrop,
.calender-modal .MuiBackdrop-root {
  transition: all 0.5s ease-in-out !important;
  backdrop-filter: blur(5px) !important;
}
.notification-dropdown .MuiModal-backdrop,
.profile-dropdown .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.distance-slider .MuiSlider-track {
  height: 2px !important;
  background-color: #0a1e41 !important;
  border: none !important;
}
.distance-slider .MuiSlider-rail {
  height: 1px !important;
  background-color: #0a1e41bb #7979796e !important;
}
.distance-slider .MuiSlider-thumb {
  height: 24px !important;
  width: 24px !important;
  background-color: var(--clr-dark-blue) !important;
}

.custom-checkbox svg {
  height: 24px !important;
  width: 24px !important;
  border: 1.5px solid #dadada !important;
  border-radius: 5px !important;
}
.custom-checkbox svg path {
  fill: transparent !important;
}
.custom-checkbox.Mui-checked svg path {
  fill: var(--clr-dark-blue) !important;
}
.custom-checkbox.Mui-checked svg {
  border: 1px solid #dadada00 !important;
}
.MuiPickersArrowSwitcher-button.Mui-disabled svg path {
  fill: #a9e8ff !important;
}

.MuiPickersArrowSwitcher-button svg path {
  fill: var(--clr-dark-blue) !important;
}

.MuiPickersDay-root {
  font-size: 14px !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: var(--clr-dark-blue) !important;
}
.MuiClock-pin,
.MuiClockPointer-root,
.MuiClockPointer-thumb {
  background-color: var(--clr-dark-blue) !important;
  border-color: var(--clr-dark-blue) !important;
}

.MuiTabs-indicator {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.MuiLinearProgress-root {
  background-color: #f5f5f5 !important;
  border-radius: 12px !important;
  height: 8px !important;
}
.MuiLinearProgress-bar {
  background-color: var(--clr-dark-blue);
  border-radius: 12px !important;
}
.emoji-review-slider .MuiSlider-thumb {
  background-color: #fff !important;
  border: 3px solid #f1f1ef !important;
}
.checkbox-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
  object-position: center;
}

.cancel-modal-textfield {
  background-color: #fff;
  border-radius: 12px;
}
.cancel-modal-textfield fieldset {
  border-color: transparent;
}

.stepper-svg svg {
  background-color: #fff;
  position: relative;
  z-index: 1;
}
.stepper-svg::after {
  content: '';
  display: block;
  height: 100%;
  border: 1px dashed var(--clr-dark-blue);
  position: absolute;
  top: 15px;
  left: 11px;
}

.selfie-swiper {
  height: 100%;
}
.selfie-swiper .swiper-button-next,
.selfie-swiper .swiper-button-prev {
  color: var(--clr-dark-blue) !important;
  background-color: #ffffff70 !important;
  border-radius: 10px !important;
  box-shadow: 0 0 10px rgba(66, 66, 66, 0.349) !important;
}

.selfie-swiper .swiper-button-next::after,
.selfie-swiper .swiper-button-prev::after {
  font-size: 30px !important;
}

.selfie-swiper .swiper-pagination {
  position: absolute !important;
  top: 15px;
  left: 90%;
  height: fit-content !important;
  width: fit-content !important;
  padding: 3px 5px;
  background: #fff;
  border-radius: 12px;
  font-size: 14px !important;
  font-weight: bold !important;
  word-spacing: -3px !important;
}

.message-input {
  position: relative;
  top: -5px;
}
.message-input fieldset {
  border: 0 !important;
  outline: 0 !important;
}
/* Swiper custom pagination -----start */
.auth-swiper .swiper-pagination,
.hero-swiper .swiper-pagination,
.applicant-swiper .swiper-pagination {
  position: relative !important;
  bottom: 30px !important;
}
.hero-swiper .swiper-pagination {
  bottom: -4px !important;
}
.applicant-swiper {
  padding-bottom: 15px !important;
}
.applicant-swiper .swiper-pagination {
  bottom: -15px !important;
}
.auth-swiper .swiper-pagination-bullet,
.hero-swiper .swiper-pagination-bullet,
.applicant-swiper .swiper-pagination-bullet {
  background: var(--clr-black) !important;
  width: 15px !important;
  height: 5px !important;
  border-radius: 10px !important;
  opacity: 1 !important;
}

.auth-swiper .swiper-pagination-bullet-active,
.hero-swiper .swiper-pagination-bullet-active,
.applicant-swiper .swiper-pagination-bullet-active {
  width: 40px !important;
  background: var(--clr-dark-blue) !important;
}
/* Swiper custom pagination -----end */

/* Auth Layout -----start */
.auth-layout-container {
  height: 100%;
  width: 100%;
}
/* Auth Swiper -----start */
.auth-swiper {
  height: 100dvh;
}

.auth-swiper-slide {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 45px;
}

.auth-swiper-img {
  max-width: 465px;
  width: 100%;
}

/* Auth Swiper -----end */

/* Auth Box -----start*/
.auth-box {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.login-box {
  max-width: 445px;
  width: 100%;
  border-radius: var(--radius-12);
  padding: 55px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.form-box {
  max-width: 355px;
}
.form-box form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.auth-signup-box {
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.auth-signup-form {
  border-radius: var(--radius-12);
  display: flex;
  justify-content: center;
}
.auth-signup-form-box {
  max-width: 940px;
  width: 100%;
}

.speciality-box {
  width: 44px !important;
  height: 44px !important;
  background-color: var(--clr-black);
  color: var(--clr-white) !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 9.5px;
  flex-shrink: 0;
  position: absolute;
}
.speciality-select {
  flex-grow: 1 !important;
}
.speciality-delete-btn {
  position: absolute !important;
  right: 0;
}
.speciality-select.Mui-disabled,
.speciality-select .Mui-disabled {
  color: #000000 !important;
  -webkit-text-fill-color: #000000 !important;
}
.speciality-select.Mui-disabled .placeholder-text {
  color: #a2a2a2 !important;
  -webkit-text-fill-color: #a2a2a2 !important;
}
.speciality-select .MuiSelect-select {
  padding: 12px 0px;
}
.placeholder-text {
  color: #a2a2a2 !important;
  -webkit-text-fill-color: #a2a2a2 !important;
}
/* Auth Box -----end*/

/* Auth Layout -----end */

/* Navbar -----start */
.navbar-container {
  max-width: 410px;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.navbar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  gap: 10px;
}
.navbar-item {
  height: 100%;
}
.navbar-link {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  color: var(--clr-black);
}
.navbar-link:hover {
  color: var(--clr-dark-blue);
  transition: color 0.5s ease;
}
.navbar-link.active {
  color: var(--clr-dark-blue);
}

.navbar-link.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 130%;
  height: 5px;
  background-color: var(--clr-dark-blue);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.credentials-tabs.active::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 5px;
  background-color: var(--clr-dark-blue);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.navbar-drawer .MuiPaper-root {
  background: #e8f0f3;
  max-width: 400px;
  width: 100%;
}
.navbar-drawer .navbar-link.active::after {
  display: none;
}
.navbar-drawer .navbar-link.active {
  background-color: #3636361f !important;
  color: var(--clr-dark-blue);
}
.navbar-link.active svg {
  fill: var(--clr-dark-blue);
}

@media (max-width: 900px) {
  .navbar {
    flex-direction: column;
  }
}
.MuiBadge-badge {
  background-color: var(--clr-dark-blue) !important;
}

.notification-dropdown .MuiPaper-root {
  background-color: transparent !important;
  top: 75px !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  width: 100% !important;
  max-width: 460px !important;
}

.profile-dropdown .MuiPaper-root {
  background-color: transparent !important;
  top: 75px !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  width: 100% !important;
  max-width: 320px !important;
}

.notification-tabs button {
  text-transform: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.notification-tab.Mui-selected {
  color: var(--clr-dark-blue) !important;
}
.notification-tabs .MuiTabs-indicator {
  background-color: var(--clr-dark-blue) !important;
  height: 5px !important;
}
/* Navbar -----end */

/* Footer -----start */
.footer-links-list {
  max-width: 400px !important;
}
.footer-links-list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  list-style: none;
}
.footer-links-list ul li {
  white-space: nowrap;
}
.footer-form {
  margin-top: 10px;
  display: flex;
  gap: 8px;
}
.footer-input input {
  padding: 11.5px 5px;
}
.privacy-links ul {
  display: flex;
  list-style: none;
  gap: 15px;
}
/* Footer -----end */

/* Home -----start */
.hero-slide-box {
  width: 100%;
  position: relative;
  border-radius: var(--radius-12);
  /* padding: 20px; */
  overflow: hidden;
  background: var(--clr-dark-blue);
}
.hero-slide-box::before {
  /* content: ""; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../images/PNG/frame.png');
  opacity: 0.15;
  z-index: 0;
}
.hero-slide-img {
  /* max-width: 500px;
  height: 248px; */
  /* margin: 0 auto; */
  position: relative;
  height: 295px;
  z-index: 5;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  /* border: 3px solid var(--clr-white); */
}
/* Home -----end */

/* Chat -----start */
.web-sender-msg-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.web-msg-profile-pic {
  height: 45px !important;
  width: 45px !important;
  border-radius: 50%;
  overflow: hidden;
}
.web-msg-box {
  width: fit-content !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #0a1e41;
}
.msg-content {
  max-width: 200px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 10px 15px;
  position: relative;
}
.msg-text {
  position: relative;
  z-index: 1;
}
.web-sender-msg-wrapper .msg-content::after {
  content: '';
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  background-color: #ffffff;
  transform: skew(-43deg, -11deg);
  left: 0px;
  bottom: 7px;
  z-index: 0;
}
.web-receiver-msg-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 10px;
}
.web-receiver-msg-wrapper .msg-content::after {
  content: '';
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  background: var(--clr-dark-blue);
  transform: skew(43deg, 11deg);
  right: 0px;
  bottom: 7px;
}
.web-receiver-msg-wrapper .msg-content {
  background-color: var(--clr-dark-blue);
  color: #ffffff;
}
/* Chat -----end */

/* Hospital detail swiper -----start */
.hospital-detail-swiper {
  border-radius: 12px;
  overflow: hidden;
}
.hospital-swiper-slide {
  height: clamp(270px, 40vw, 372px);
  border-radius: 12px;
  overflow: hidden;
}
.hospital-detail-swiper-thumbnail {
  margin-top: 15px;
}
.hospital-detail-swiper-thumbnail .swiper-slide {
  /* width: fit-content !important; */
  cursor: pointer;
}
.hospital-swiper-thumbnail-slide {
  /* width: 110px !important; */
  height: 92px;
  border-radius: 12px;
  overflow: hidden;
}
/* Hospital detail swiper -----end */

.fc-daygrid-day-frame {
  background-color: #fff;
}
.fc-daygrid-event-harness {
  background-color: #cecece62;
}
.fc-daygrid-event-harness a {
  color: #0ca8df !important;
}
.fc-daygrid a {
  color: #0a1e41;
}
.fc .fc-toolbar {
  flex-wrap: wrap;
  gap: 20px;
}
.fc-toolbar-title {
  font-size: 20px !important;
  color: #0a1e41;
  font-weight: 600;
}
.fc-button-primary {
  padding: 4px 8px !important;
}
.fc-non-business {
  background-color: #d6d6d61e !important;
}

.facility-select .MuiSelect-select {
  white-space: wrap !important;
}

.applicant-swiper .swiper-slide {
  width: 400px !important;
  @media (max-width: 600px) {
    width: 100% !important;
  }
}
/* .upcoming-shift-swiper .upcoming-shift-swiper-slide {
  width: 330px;
} */

.animate-bounce {
  animation: bounce 0.8s infinite;
}

.delay-1 {
  animation-delay: 0s;
}

.delay-2 {
  animation-delay: 0.2s;
}

.delay-3 {
  animation-delay: 0.4s;
}
