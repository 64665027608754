//------------ scroll hide --------------
.simplebar-content-wrapper,
.chat-scroll {
  -ms-overflow-style: none;
  /* hide scroll */
  scrollbar-width: none;
  /* hid scroll Firefox */
}

.simplebar-content-wrapper::-webkit-scrollbar,
.chat-scroll::-webkit-scrollbar {
  display: none; //hide scroll
}

//------------ sidemenu scroll --------------
.simplebar-content-wrapper {
  height: 100%;
  max-height: 100%;
  width: auto;
  max-width: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  /* hide scroll */
  scrollbar-width: none;
  /* hid scroll Firefox */
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px !important;
  right: 0px !important;
  margin: 0px;
  resize: none !important;
  padding: 0px;
  -webkit-overflow-scrolling: touch;
}

.tooltip {
  font-size: 12px !important;
  border-radius: 10px !important;
}

.loading-img {
  max-width: 600px;
  width: calc(100vw - 100px);
  margin: 0 auto;
}

.loading-img img {
  width: 100%;
  transform-origin: center top;
  animation: scale 2000ms ease-in-out infinite;
}

/* remove number input increment/decrement icon*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* SweetAlert popup */
.custom-swal-popup {
  background: #f1f5f9 !important;
  -webkit-box-shadow: 10px 10px 35px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 35px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 35px 0px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
}

.swal2-title.Swal-title-class {
  color: #000;
  font-size: 20px;
}

.login-page .form-input {
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.875rem;
}

.login-page .btn {
  display: inline-block;
  border-radius: 0.375rem;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  font-size: 0.8125rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  transition-timing-function: linear;
}

.btn {
  display: inline-block;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  font-size: 0.8125rem;
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: linear;
  letter-spacing: 0.5px;
}

//--------- select menu css ------------

.custom-select-container {
  background-color: #fff;
  color: #000;
  border-radius: 5px;
}

.custom-select-menu,
.custom-select-option,
.custom-select-single-value {
  background-color: #fff;
  color: #000;
}

.custom-select-option:hover {
  background-color: #f0f0f0;
}

.custom-select-container .react-select__control,
.custom-select-container .react-select__menu,
.custom-select-container .react-select__option,
.custom-select-container .react-select__option--is-focused,
.custom-select-container .react-select__single-value {
  background-color: #fff !important;
  color: #000 !important;
  border: none;
}

.custom-select-container
  .react-select__control
  .react-select__indicator-separator {
  background: #a1a1aa;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: 5px 5px 12px -7px rgba(0, 0, 0, 0.33) !important;
}

.box-shadow-none .react-select__control {
  box-shadow: none !important;
}

// ------------- help and support --------------
.search.form-input {
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.875rem;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.faq-table tbody tr td:nth-child(2),
.faq-table tbody tr td:nth-child(3),
.email-table tbody tr td:nth-child(4) {
  min-width: 200px !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background: none !important;
}

.admin-tabs .MuiTab-textColorPrimary {
  border: none;
  outline: none;
  color: #777 !important;

  &.Mui-selected {
    transition-timing-function: linear;
    transition-duration: 300ms;
    transition-property: all;
    border-width: 1px;
    border-radius: 0.375rem;
    border-color: transparent;
    background-color: #fff !important;
    color: #3b82f6 !important;
    border: none;
  }
}

.admin-tabs .MuiTabs-scroller {
  .MuiTabs-indicator {
    background-color: transparent !important;
    display: none !important;
  }
}

.admin-tabs .MuiTabs-flexContainer {
  background-color: #eee !important;
  border-radius: 0.375rem !important;
  padding: 5px !important;

  &.MuiTab-root {
  }
}

// For Main Dropdown Button Icon
.dropdown-button:before {
  content: "\ea6e";
}

.dropdown-button.active:before {
  content: "\ea4e";
}

// For Sub Dropdown Button Icon
.sub-drop-down-btn::before {
  content: "";
}

.sub-drop-down-btn.active::before {
  content: "";
}

.sub-drop-down-btn::after {
  content: "\ea6e";
}

.sub-drop-down-btn.active::after {
  content: "\ea4e";
}

// For Hide Icon From Sub Items
.dropdown-button.active .nav-sub-no-before::before {
  content: "";
}

.overflow-break-word {
  overflow-wrap: break-word;
}

.radio-label .MuiTypography-root {
  font-size: 14px;
  color: #000000de;
  font-family: public sans;
  font-weight: 600;
}

.credential-tab {
  .MuiTabs-flexContainer {
    padding: 0 !important;
    row-gap: 8px !important;
    border-radius: 4px !important;
    background-color: transparent !important;
  }

  .Mui-selected {
    background-color: #e2edff !important;
    border-radius: 4px !important;
  }

  .MuiTab-root {
    font-size: 0.875rem !important;
  }

  .MuiTabPanel-root {
    padding: 0 !important;
  }
}

// For search input border
.search-input-border-style {
  border: 1px solid rgb(226 232 240 / var(--tw-border-opacity));
  outline: 0;
}

.search-input-border-style:focus {
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.search-input-border-style-error,
.search-input-border-style-error:focus {
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-none-importat {
  border: none !important;
}

.react-select__placeholder.css-1jqq78o-placeholder {
  color: #94a3b8;
}

.fc-list-event-graphic,
.fc-daygrid-event-dot,
.fc-event-time {
  display: none;
}
.fc-event-title {
  color: #fff;
  text-align: center;
}
