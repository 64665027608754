@media (max-width: 1920px) {
}
@media (max-width: 1875px) {
}
@media (max-width: 1775px) {
}
@media (max-width: 1675px) {
}
@media (max-width: 1575px) {
}
@media (max-width: 1475px) {
}
@media (max-width: 1375px) {
}
@media (max-width: 1275px) {
}
@media (max-width: 1175px) {
}
@media (max-width: 1075px) {
}
@media (max-width: 975px) {
}
@media (max-width: 901px) {
  .auth-layout-grid-item {
    min-width: 100% !important;
  }
}
@media (max-width: 900px) {
  .login-box {
    max-width: 645px;
  }
  .form-box {
    max-width: 500px;
  }
  .professional-signup-box {
    margin-top: 15px !important ;
  }
  .submit-btn-container {
    max-width: 100% !important;
    margin-left: 0;
  }
  .auth-swiper {
    height: auto;
  }
  .auth-swiper-slide {
    min-height: 80dvh;
  }
}
@media (max-width: 875px) {
}
@media (max-width: 775px) {
  .otp-input {
    gap: 10px !important;
  }
  .otp-container {
    padding: 20px !important;
  }
}
@media (max-width: 710px) {
  .schedule-headerbar-button {
    width: auto !important;
    white-space: nowrap !important;
  }
}
@media (max-width: 675px) {
  .ongoing-schedule-card {
    flex-direction: column;
  }
  .schedule-tabs-headerbar {
    flex-direction: column;
  }
}
@media (max-width: 600px) {
}
@media (max-width: 575px) {
  .notification-dropdown .MuiPaper-root,
  .profile-dropdown .MuiPaper-root  {
    max-width: 100% !important;
    left: 0 !important;
    padding: 0 15px;
  }
}
@media (max-width: 475px) {
  .schedule-header-tabs {
    overflow-y: auto !important;
  }
}
@media (max-width: 375px) {
  .jobs-header-tabs {
    flex-wrap: wrap !important;
  }
  .tabs-headerbar {
    align-items: baseline !important;
  }
  /* .tabs-headerbar-button-group {
    margin-right: 5em !important;
  } */
}
@media (max-width: 375px) {
  .tabs-headerbar-button-group {
    margin-right: auto !important;
  }
  .responsive-pie-chart {
    width: 100% !important;
  }
}
@media (max-width: 275px) {
  .tabs-headerbar {
    flex-wrap: wrap !important;
  }
  .tabs-headerbar-button-group {
    margin-left: auto !important;
  }
  .tabs-headerbar-button {
    padding: 5px 7px !important;
  }
}
