/* @layer tailwind-base, antd; */

@layer tailwind-base;

@layer tailwind-base {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;

.plain-select .custom-select-container {
  border: none !important;
}
.quill-editor {
  border-radius: 8px !important ;
  border: 1px solid #e2e8f0 !important;
  margin-top: 0.5rem;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  border-bottom: 1px solid #e2e8f0 !important;
}

.web-cms ul,
.web-cms ol {
  list-style: revert !important;
  /* margin: revert !important; */
  padding: revert !important;
}
