* {
  font-family: "Proxima Nova", sans-serif !important;
}

/**************OTP PAGE**************/
.otp-container {
  max-width: 445px;
  border-radius: var(--radius-12);
  padding: 40px;
}

.otp-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.otp-image {
  /* max-width: 213px;
  max-height: 150px; */
  display: flex;
}

.otp-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.otp-input {
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.otp-input div {
  background-color: #fff !important;
}

.resend-otp-icon {
  width: 24px;
  height: 24px;
}

.resend-otp-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 34px;
  padding: 10px 15px;
  border-radius: 12px;
  max-width: 280px;
}

/**************RESET PASSWORD PAGE**************/

.reset-container {
  max-width: 425px;
  border-radius: var(--radius-12);
  padding: 35px;
}

.reset-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 37px;
}

.reset-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 37px;
  width: 100%;
}

/**************FORGOT PASSWORD PAGE**************/

.forgot-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  padding: 0 75px;
}

.forgot-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
}

/**************SUCCESS MODAL**************/
.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 29px;
}

/**************PROFESSIONAL SIGNUP PAGE**************/

.professional-signup-box {
  align-self: flex-start;
  margin-top: 47px;
}

.submit-btn-container {
  max-width: 350px;
  margin-top: 32px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
